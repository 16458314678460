import { Checkbox, Dropdown, Spinner, Table, TableBody, TableCellLayout, TableHeader, TableHeaderCell, Divider, TableRow, TableCell, Button, Input, Field, Switch, Option, Tooltip } from '@fluentui/react-components';
import * as React from 'react';
import './input-list.scss';
import { TableColumnType } from '../../../interfaces/konfiguration/konfiguration.interface';
import { deleteRowKonfigTagesordnung, insertTagesordnungpunktVorlageDto, patchKonfigTagesordnung, updateKonfigTagesordnung } from '../../../api/konfig-tagesordnungspunktvorlage-api';
import NewTagesordnungspunkt from '../../../models/tagesordnung/tagesordnungspunkt-subitem';
import { getAllMitglieders } from '../../../api/konfig-mitglieder-api';
import { getUserProfilesAsync } from '../../../api/users';
import { getAgenda } from '../../../api/agenda-dto-api';
import IGraphUser from '../../../models/graph-user';
import { handleTokenAccessFailure } from '../../../Helpers/apiHelper';
import { getTagesordnungPunktKonfig, getTagesordnungPunktKonfigDefaults, getTagesordnungPunktKonfigGremium } from '../../../api/konfiguration-api';
import { patchCheckbox } from '../../../api/agenda-dto-api';
import { deleteRowTagesordnung, getTagesordnungSitzungDto, insertTagesordnungpunktDto, updateTagesordnungDto, patchTagesordnungDtoPunkt, getTagesordnungDto } from '../../../api/tagesordnungspunkt-api';
import ITagesordnungspunkt from '../../../models/tagesordnung/tagesordnungspunkt';
import { patchTagesordnungDto } from '../../../api/tagesordnung-api';
import { ConfirmResetToDefaultTagesordnung, LoaderMessage } from '../../../constants/textLabels';
import KonfigurationSpeichernModal from '../../konfiguration/konfiguration-speichern-dialog/konfiguration-speichern-dialog';
import { MeetingFormStepStatus } from '../../../enums/meeting-form-step-status.enum';
import ConfirmDialog from '../dialog/confirm-dialog';
import { Guid } from 'guid-typescript';
import { VordefinierterTyp } from '../../../enums/vordefinierterTyp.enum';
import { AddIcon, ArrowDownIcon, ArrowUpIcon, CheckIcon, CloseIcon, InfoIcon, MoreHorizontalIcon, PencilIcon, TrashIcon } from '../../../utils/icons';
import ResetToDefaultButton from '../ResetToDefaultButton/ResetToDefaultButton';

interface IInputListProps {
    id?: string;
    data;
    disabled?: boolean;
    createErrorNotification?: Function;
    setResetRowsFunction?: Function;
    isSitzung: string | null
    gremiumDetailId?: string;
    dataHasChanged: Function;
    orderHasChanged: Function;
    gremiumDetails?: any;
}

interface IInputListState {
    data?: any[];
    rows?: any[];
    editing: number;
    tableRef;
    initialUpdate: boolean;
    deletedRows: number[];
    moveUpBtnRefs;
    moveDownBtnRefs;
    dauerChecked: boolean;
    verantwortlichChecked: boolean;
    anlageChecked: boolean;
    dauerEdit: number;
    responiblePersonIdEdit: string;
    nameEdit: string;
    istBeschlussfassungVorgesehenEdit: boolean;
    anlageEdit: boolean;
    mitglieders: IGraphUser[]
    addSubitem: boolean
    dauerEditSubitem: number;
    responiblePersonIdEditSubitem: string;
    nameEditSubitem: string;
    istBeschlussfassungVorgesehenEditSubitem: boolean;
    anlageEditSubitem: boolean;
    rowsData: ITagesordnungspunkt[];
    addNewRow: boolean;
    newRowIndex: number;
    rowsDataSitzung: ITagesordnungspunkt[];
    validateTagesordnungPunkt: boolean;
    isLoading: boolean;
    isDialogOpen: boolean;
    clickedRow: number;
    vordefinierterTypEdit: VordefinierterTyp;
    vordefinierterTypEditSubitem: VordefinierterTyp;
    confirmDialogRef;
    actionListRef;
    defaultTableValues: {
        typ: VordefinierterTyp,
        name: string,
        mitBeschluss: boolean
    }[]
}

const vordefinierterTyp = [
    { id: Number(VordefinierterTyp.Allgemein), type: "Allgemein" },
    { id: Number(VordefinierterTyp.Organisatorisches), type: "Organisatorisches" },
    { id: Number(VordefinierterTyp.ErgänzungenTO), type: "Ergänzungen TO" },
    { id: Number(VordefinierterTyp.ProtokollDerLetztenSitzung), type: "Protokoll der letzten Sitzung" },
    { id: Number(VordefinierterTyp.BerichteUndInformationen), type: "Berichte und Informationen" },
    { id: Number(VordefinierterTyp.Verschiedenes), type: "Verschiedenes" },
    { id: Number(VordefinierterTyp.NeuerSitzungstag), type: "Neuer Sitzungstag" },
];

class TagesordnungInputList extends React.Component<IInputListProps, IInputListState> {

    constructor(props) {
        super(props);

        this.state = {
            actionListRef: React.createRef(),
            vordefinierterTypEditSubitem: VordefinierterTyp.Allgemein,
            vordefinierterTypEdit: VordefinierterTyp.Allgemein,
            dauerChecked: true,
            verantwortlichChecked: true,
            anlageChecked: true,
            editing: -1, //if value is -1, it IS NOT EDIT mode, if value is === to row index, it IS EDIT mode
            tableRef: React.createRef(),
            initialUpdate: true,
            deletedRows: [],
            moveUpBtnRefs: [],
            moveDownBtnRefs: [],
            nameEdit: '',
            istBeschlussfassungVorgesehenEdit: false,
            anlageEdit: false,
            dauerEdit: 5,
            responiblePersonIdEdit: "",
            mitglieders: [],
            addSubitem: false,
            nameEditSubitem: '',
            istBeschlussfassungVorgesehenEditSubitem: false,
            anlageEditSubitem: false,
            dauerEditSubitem: 5,
            responiblePersonIdEditSubitem: "",
            rowsData: [],
            rowsDataSitzung: [],
            addNewRow: false,
            newRowIndex: 0,
            validateTagesordnungPunkt: false,
            isLoading: false,
            isDialogOpen: false,
            clickedRow: -1,
            confirmDialogRef: React.createRef(),
            defaultTableValues: []
        };
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
        if (!this.props.isSitzung) {
            const getGremiumData = this.props.gremiumDetails
            if (getGremiumData) {
                this.setState({
                    dauerChecked: getGremiumData.showDauerOnTagesordnung,
                    verantwortlichChecked: getGremiumData.showResponsiblePersonOnTagesordnung,
                    anlageChecked: getGremiumData.showHasAttachmentsOnTagesordnung,
                })
            }
        }
        const allMitglieders = await getAllMitglieders(handleTokenAccessFailure)
        if (!this.state.defaultTableValues.length) {
            const resp = await getTagesordnungPunktKonfigDefaults(handleTokenAccessFailure)
            const tempDefault = this.state.defaultTableValues
            resp.data.forEach((t) => {
                tempDefault.push({ typ: t.vordefinierterTyp, name: t.name, mitBeschluss: t.istBeschlussfassungVorgesehen })
            })

            this.setState({ defaultTableValues: tempDefault })
        }
        const gremiumId = localStorage.getItem("selectedAuschuss")
        const mitgliedersFromGremium = allMitglieders.data.filter((m) => m.gremiumId === gremiumId)
        // this.setState({ rowsData: this.props.data }, this.props.isSitzung ? this.getRowsSitzung : this.getRows)
        let mitgliedersFromKonfiguration: string[] = []
        mitgliedersFromGremium.forEach((m) => {
            if (m.graphUserId != Guid.createEmpty())
                mitgliedersFromKonfiguration.push(m.graphUserId)
        });
        const user = await getUserProfilesAsync(mitgliedersFromKonfiguration, handleTokenAccessFailure)
        const emptyUser = {
            id: Guid.createEmpty(),
            displayName: '',
            isActive: false
        }
        const mitgliedersDataForDropdown: IGraphUser[] = []
        mitgliedersDataForDropdown.push(emptyUser)
        if (user.data) {
            user.data.forEach((u) => mitgliedersDataForDropdown.push(u))
        }
        this.setState({ mitglieders: mitgliedersDataForDropdown })
        if (this.props.setResetRowsFunction) { this.props.setResetRowsFunction(this.resetRows); }
        this.setWideCellsWidth();
    }

    handleClickOutside = (e) => {
        if (this.state.actionListRef.current && !this.state.actionListRef.current.contains(e.target)) {
            const isActionListItem = e.target.closest('.action-list-item');

            if (!isActionListItem) {
                this.closeAllActionLists();
            }
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    async componentDidUpdate() {
        if (this.props.data && this.props.data.length > 0 && this.state.initialUpdate) {
            await this.setState({
                data: this.props.data,
                initialUpdate: false
            });
            if (this.props.isSitzung) {
                await this.getAgendaDataIfSitzung(this.props.isSitzung)
                await this.getRowsSitzung()
            } else {
                await this.getRows()
            }
        }
    }

    getAgendaDataIfSitzung = async (isSitzung: string) => {
        const agenda = await getAgenda(isSitzung)
        const getAgendaItems = await getTagesordnungSitzungDto(isSitzung, handleTokenAccessFailure)
        if (agenda.data.length) {
            this.setState({
                rowsDataSitzung: getAgendaItems.data,
                data: getAgendaItems.data,
                dauerChecked: agenda.data[0].showDauer,
                verantwortlichChecked: agenda.data[0].showResponsiblePerson,
                anlageChecked: agenda.data[0].showHasAttachments,
            })
        }
    }

    actionsCell = (rowIndex, isSubitem, row, hasSubitems) => {
        if (rowIndex !== this.state.editing || rowIndex === -1) {
            return <> <Button
                className="loadActionList"
                icon={<span style={{ fontSize: "20px" }}><MoreHorizontalIcon /></span>}
                appearance='transparent'
                disabled={this.state.addNewRow}
                title="Weitere Aktionen"
                onClick={(e) => { this.toggleActionList(e) }}
                ref={this.state.actionListRef}
            />
                <ul className='action-list' hidden>
                    <li className='action-list-item' onClick={e => this.handleBearbeitenClick(e, rowIndex)}>
                        <span style={{ fontSize: "20px" }}><PencilIcon /></span>
                        Bearbeiten
                    </li>
                    {!hasSubitems &&
                        <li className='action-list-item' onClick={e => this.handleEntfernenClick(e, row)}>
                            <span style={{ fontSize: "20px" }}><TrashIcon /></span>
                            Entfernen
                        </li>
                    }
                </ul>
            </>
        }
        else {
            return <>
                <div
                    className='editFinalizationBtnGroup'>
                    {!isSubitem && <Button appearance='transparent' icon={<span className='icon-add-color'><AddIcon /></span>} title='Unterpunkt' key={'add'} onClick={e => this.handleAddSubitem(e)}></Button>}
                    <Button appearance='transparent' icon={<span className='icon-check-color'><CheckIcon /></span>} title='speichern' key={'check'} onClick={e => { isSubitem && row.parentItemId === null ? this.addSubitem(rowIndex, row) : this.setState({ isLoading: true }, () => this.handleCheckClick(row)) }}></Button>
                    <Button appearance='transparent' icon={<span className='icon-close-color'><CloseIcon /></span>} title='abbrechen' key={'close'} onClick={e => this.handleCloseClick(e)}></Button>
                </div>
            </>
        }
    }

    addSubitem = async (rowIndex, row) => {
        const rowData = this.props.isSitzung ? this.state.rowsDataSitzung : this.state.rowsData
        const selectedRow = rowData.find((val) => val.id === row.id)
        let validation = this.validateRowSubitem()
        this.setState({ validateTagesordnungPunkt: true })
        if (selectedRow && this.state.editing === rowIndex && validation) {
            this.setState({ isLoading: true })
            const checkForSubitems = rowData!.filter((val) => val.parentItemId === selectedRow.id).length
            const subitem: NewTagesordnungspunkt = {
                name: this.state.nameEditSubitem,
                sequenz: checkForSubitems + 1,
                dauer: this.state.dauerChecked ? this.state.dauerEditSubitem : 5,
                istBeschlussfassungVorgesehen: this.state.istBeschlussfassungVorgesehenEditSubitem,
                responsiblePersonId: this.state.responiblePersonIdEditSubitem || null,
                parentItemId: selectedRow.id,
                hasAttachments: this.state.anlageChecked ? this.state.anlageEditSubitem : false,
                vordefinierterTyp: this.state.vordefinierterTypEditSubitem
            }
            if (this.props.isSitzung) {
                const agenda = await getAgenda(this.props.isSitzung)
                const agendaId = agenda.data[0].id
                subitem.tagesordnungId = agendaId
                this.props.dataHasChanged(true)
                await insertTagesordnungpunktDto(subitem, handleTokenAccessFailure)
                if (agenda.data.tagesordnungstatus === MeetingFormStepStatus.Processed) {
                    await patchTagesordnungDto(agendaId, "tagesordnungstatus", MeetingFormStepStatus.NeedsReprocessing, handleTokenAccessFailure)
                }
            } else {
                await insertTagesordnungpunktVorlageDto(subitem, handleTokenAccessFailure)
            }
            this.setState(
                {
                    addSubitem: false,
                    editing: -1,
                    nameEditSubitem: '',
                    dauerEditSubitem: 5,
                    istBeschlussfassungVorgesehenEditSubitem: false,
                    anlageEditSubitem: false,
                    responiblePersonIdEditSubitem: '',
                    isLoading: false
                }
            )
            this.props.isSitzung ? await this.getRowsSitzung() : await this.getRows()
        }
    }

    moveRowUp = async (e, currentIndex: number) => {
        this.disableUpDownBtns()
        const rowsData = this.props.isSitzung ? this.state.rowsDataSitzung : this.state.rowsData

        const currentSequenz = rowsData[currentIndex].sequenz;
        let previousSequenz
        if (currentIndex > 0 && rowsData && rowsData[currentIndex].parentItemId === null) {
            previousSequenz = rowsData.find((val) => (val.sequenz === currentSequenz - 1) && val.parentItemId === null);
        }
        //move row up for subitem
        if (currentIndex > 0 && rowsData && rowsData[currentIndex].parentItemId !== null) {
            previousSequenz = rowsData.find((val) => (val.sequenz === currentSequenz - 1) && val.parentItemId === rowsData[currentIndex].parentItemId);
        }
        if (this.props.isSitzung) {
            if (previousSequenz?.sequenz && previousSequenz?.id) {
                await patchTagesordnungDtoPunkt(previousSequenz?.sequenz, "sequenz", rowsData[currentIndex].id, handleTokenAccessFailure)
                await patchTagesordnungDtoPunkt(currentSequenz, "sequenz", previousSequenz?.id, handleTokenAccessFailure)
                this.props.orderHasChanged(true)
                await this.getRowsSitzung()
            }
        } else {
            if (previousSequenz?.sequenz && previousSequenz?.id) {
                await patchKonfigTagesordnung(previousSequenz?.sequenz, "sequenz", rowsData[currentIndex].id, handleTokenAccessFailure)
                await patchKonfigTagesordnung(currentSequenz, "sequenz", previousSequenz?.id, handleTokenAccessFailure)
                await this.getRows()
            }
        }
        this.enableUpDownBtns()
    }

    moveRowDown = async (e, currentIndex: number) => {
        this.disableUpDownBtns()
        const rowsData = this.props.isSitzung ? this.state.rowsDataSitzung : this.state.rowsData

        const currentSequenz = rowsData[currentIndex].sequenz;
        let previousSequenz
        if (rowsData && currentIndex < rowsData.length - 1 && rowsData[currentIndex].parentItemId === null) {
            previousSequenz = rowsData.find((val) => (val.sequenz === currentSequenz + 1) && val.parentItemId === null);
        }
        //move row down for subitem
        if (rowsData && currentIndex < rowsData.length - 1 && rowsData[currentIndex].parentItemId !== null) {
            previousSequenz = rowsData.find((val) => (val.sequenz === currentSequenz + 1) && val.parentItemId === rowsData[currentIndex].parentItemId);
        }
        if (this.props.isSitzung) {
            if (previousSequenz?.sequenz && previousSequenz?.id) {
                await patchTagesordnungDtoPunkt(previousSequenz?.sequenz, "sequenz", rowsData[currentIndex].id, handleTokenAccessFailure)
                await patchTagesordnungDtoPunkt(currentSequenz, "sequenz", previousSequenz?.id, handleTokenAccessFailure)
                this.props.orderHasChanged(true)
                await this.getRowsSitzung()
            }
        } else {
            if (previousSequenz?.sequenz && previousSequenz?.id) {
                await patchKonfigTagesordnung(previousSequenz?.sequenz, "sequenz", rowsData[currentIndex].id, handleTokenAccessFailure)
                await patchKonfigTagesordnung(currentSequenz, "sequenz", previousSequenz?.id, handleTokenAccessFailure)
                await this.getRows()
            }
        }
        this.enableUpDownBtns()
    }

    handleAddSubitem = async (e) => {
        const typ = vordefinierterTyp.find((u) => u.id === this.state.vordefinierterTypEdit)!.id
        this.setState({ vordefinierterTypEditSubitem: typ })
        if (typ) {
            const defaultValues = this.state.defaultTableValues
            if (typ == VordefinierterTyp.Allgemein) {
                this.setState({ nameEditSubitem: '', istBeschlussfassungVorgesehenEditSubitem: false })
                return
            }
            if (typ === VordefinierterTyp.NeuerSitzungstag) {
                this.setState({ nameEditSubitem: 'Neuer Sitzungstag: Organisatorisches und Beschlussfähigkeit', istBeschlussfassungVorgesehenEditSubitem: false })
                return
            }

            const findValue = defaultValues.find((v) => v.typ == typ)
            this.setState({ nameEditSubitem: findValue!.name, istBeschlussfassungVorgesehenEditSubitem: findValue!.mitBeschluss })
        }
        this.setState({ addSubitem: true })
    }

    handleBearbeitenClick = async (e, rowIndex) => {
        if (this.state.editing !== -1) {
            this.setState({ isDialogOpen: true, clickedRow: rowIndex })
            return
        }
        let rowToEdit = this.props.isSitzung ? this.state.rowsDataSitzung : this.state.rowsData
        const getRow = rowToEdit?.find((row, index) => index === rowIndex)
        if (getRow) {
            this.setState({
                nameEdit: getRow.name,
                anlageEdit: getRow.hasAttachments!,
                istBeschlussfassungVorgesehenEdit: getRow.istBeschlussfassungVorgesehen!,
                dauerEdit: getRow.dauer!,
                dauerEditSubitem: getRow.dauer!,
                vordefinierterTypEdit: getRow.vordefinierterTyp
            })
        }
        this.setState({
            editing: rowIndex,
            validateTagesordnungPunkt: true,
        }, this.props.isSitzung ? await this.getRowsSitzung : await this.getRows);
        this.closeAllActionLists();
    }

    // if (window.confirm("Löschen bestätigen: " + row.name)) {
    handleEntfernenClick = async (e, row: ITagesordnungspunkt) => {
        this.state.confirmDialogRef.current.showConfirmDialog("Löschen bestätigen: " + row.name, async () => {
            this.setState({ isLoading: true })
            if (this.props.isSitzung) {
                await deleteRowTagesordnung(row.id, handleTokenAccessFailure)
                if (row.parentItemId === null) {
                    const patchPromises = this.state.rowsDataSitzung.map(async (val) => {
                        if (val.sequenz > row.sequenz && val.parentItemId === null) {
                            await patchTagesordnungDtoPunkt(val.sequenz - 1, "sequenz", val.id, handleTokenAccessFailure)
                        }
                    })
                    await Promise.all(patchPromises)
                } else {
                    const patchPromises = this.state.rowsDataSitzung.map(async (val) => {
                        if (val.sequenz > row.sequenz && val.parentItemId === row.parentItemId) {
                            await patchTagesordnungDtoPunkt(val.sequenz - 1, "sequenz", val.id, handleTokenAccessFailure)
                        }
                    })
                    await Promise.all(patchPromises)
                }
                const newData = await getTagesordnungSitzungDto(this.props.isSitzung, handleTokenAccessFailure)
                this.setState({ rowsDataSitzung: newData.data })
                await this.getRowsSitzung()
                this.closeAllActionLists();
                this.props.dataHasChanged(true)
            } else {
                await deleteRowKonfigTagesordnung(row.id, handleTokenAccessFailure)
                if (row.parentItemId === null) {
                    const patchPromises = this.state.rowsData.map(async (val) => {
                        if (val.sequenz > row.sequenz && val.parentItemId === null) {
                            await patchKonfigTagesordnung(val.sequenz - 1, "sequenz", val.id, handleTokenAccessFailure)
                        }
                    })
                    await Promise.all(patchPromises)
                } else {
                    const patchPromises = this.state.rowsData.map(async (val) => {
                        if (val.sequenz > row.sequenz && val.parentItemId === row.parentItemId) {
                            await patchKonfigTagesordnung(val.sequenz - 1, "sequenz", val.id, handleTokenAccessFailure)
                        }
                    })
                    await Promise.all(patchPromises)
                }
                const newData = await getTagesordnungPunktKonfig(handleTokenAccessFailure)
                this.setState({ rowsData: newData.data })
                await this.getRows()
                this.closeAllActionLists();
            }
        })
        this.closeAllActionLists();
    }

    toggleActionList = (e) => {
        const table = this.state.tableRef.current;
        if (table.classList.contains('table__in-sequence-edit')) {
            return;
        }

        const isCurrentlyDisplayed = !e.currentTarget.nextElementSibling.hasAttribute('hidden');
        this.closeAllActionLists();
        if (isCurrentlyDisplayed) {
            e.currentTarget.nextElementSibling.setAttribute('hidden', '');
        } else {
            e.currentTarget.nextElementSibling.removeAttribute('hidden');
            e.currentTarget.nextElementSibling.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth' });
        }
    }

    closeAllActionLists = () => {
        const actionLists = [].slice.call(document.getElementsByClassName('action-list'));
        if (actionLists) {
            actionLists.forEach(actionList => {
                let actionListEl: HTMLElement = actionList;
                actionListEl.hidden = true;
            });
        }
    }

    setWideCellsWidth = () => {
        const wideCells = [].slice.call(document.getElementsByClassName('wide-cell'));
        if (wideCells) {
            let max = 0;
            wideCells.forEach(wideCell => {
                let wideCellEl: HTMLElement = wideCell;
                if (wideCellEl.clientWidth > max) max = wideCellEl.clientWidth;
            });

            wideCells.forEach(wideCell => {
                let wideCellEl: HTMLElement = wideCell;
                wideCellEl.style.minWidth = max + 'px';
            });
        }
    }

    addNewRow = async () => {
        let data = this.props.isSitzung ? this.state.rowsDataSitzung : this.state.rowsData;
        const getLastIndex = data.length
        this.setState({ editing: getLastIndex, newRowIndex: getLastIndex, addNewRow: true, validateTagesordnungPunkt: true }, () => this.subitemForm(getLastIndex, {}))
        const nextSequenz = data.filter((val) => val.parentItemId === null).length
        let validation = this.validateRow()

        if (this.state.editing === getLastIndex && validation) {
            const subitem: NewTagesordnungspunkt = {
                name: this.state.nameEdit,
                sequenz: nextSequenz + 1,
                dauer: this.state.dauerEdit,
                istBeschlussfassungVorgesehen: this.state.istBeschlussfassungVorgesehenEdit,
                responsiblePersonId: this.state.responiblePersonIdEdit || null,
                parentItemId: null,
                hasAttachments: this.state.anlageEdit,
                vordefinierterTyp: this.state.vordefinierterTypEdit
            }
            if (this.props.isSitzung) {
                const agenda = await getAgenda(this.props.isSitzung)
                const agendaId = agenda.data[0].id
                subitem.tagesordnungId = agendaId
                await insertTagesordnungpunktDto(subitem, handleTokenAccessFailure)
                if (agenda.data.tagesordnungstatus === MeetingFormStepStatus.Processed) {
                    await patchTagesordnungDto(agendaId, "tagesordnungstatus", MeetingFormStepStatus.NeedsReprocessing, handleTokenAccessFailure)
                }
                this.props.dataHasChanged(true)
            } else {
                await insertTagesordnungpunktVorlageDto(subitem, handleTokenAccessFailure)
            }
            this.props.isSitzung ? await this.getRowsSitzung() : await this.getRows()
            this.setState(
                {
                    editing: -1,
                    addNewRow: false,
                    nameEdit: '',
                    dauerEdit: 5,
                    istBeschlussfassungVorgesehenEdit: false,
                    anlageEdit: false,
                    responiblePersonIdEdit: '',
                    vordefinierterTypEdit: VordefinierterTyp.Allgemein
                }
            )
        }
        this.setState({ isLoading: false })
    }

    private createRowData = (tableRow) => {
        return {
            id: tableRow.id,
            createdBy: tableRow.createdBy,
            createdOn: tableRow.createdOn,
            modifiedBy: tableRow.modifiedBy,
            modifiedOn: tableRow.modifiedOn,
            dauer: this.state.dauerEdit,
            hasAttachments: this.state.anlageEdit,
            istBeschlussfassungVorgesehen: this.state.istBeschlussfassungVorgesehenEdit,
            istVordefiniert: tableRow.istVordefiniert,
            name: this.state.nameEdit,
            parentItemId: tableRow.parentItemId || null,
            responsiblePersonId: tableRow.responsiblePersonId ? tableRow.responsiblePersonId : this.state.responiblePersonIdEdit || null,
            sequenz: tableRow.sequenz,
            tagesordnungsNummer: tableRow.tagesordnungsNummer || "",
            vordefinierterTyp: this.state.vordefinierterTypEdit,
            [this.props.isSitzung ? "tagesordnungId" : "gremiumId"]: this.props.isSitzung ? tableRow.tagesordnungId : tableRow.gremiumId,
        }
    }

    resetRows = async() => {
        this.setState({ data: this.props.data }, this.props.isSitzung ? await this.getRowsSitzung : await this.getRows);
    }

    validateRow = () => {
        if (!this.state.nameEdit) {
            return false
        }
        return true
    }

    validateRowSubitem = () => {
        if (!this.state.nameEditSubitem) {
            return false
        }
        return true
    }

    handleCheckClick = async (rowData) => {
        const getRow = this.state.rowsData?.find((row) => row.id === rowData.id)
        const getRowSitzung = this.state.rowsDataSitzung?.find((row) => row.id === rowData.id)
        this.setState({ validateTagesordnungPunkt: true })
        if (this.props.isSitzung) {
            let validation = this.validateRow()
            if (validation) {
                const newRowDataSitzung: ITagesordnungspunkt = this.createRowData(getRowSitzung);
                await updateTagesordnungDto(newRowDataSitzung, handleTokenAccessFailure)
                let rowsData = this.state.rowsDataSitzung
                this.props.dataHasChanged(true)
                this.setState({
                    data: rowsData,
                    editing: -1,
                    validateTagesordnungPunkt: false,
                    responiblePersonIdEdit: '',
                    nameEdit: '',
                    istBeschlussfassungVorgesehenEdit: false,
                    vordefinierterTypEdit: VordefinierterTyp.Allgemein,
                }, await this.getRowsSitzung);
            }
        } else {
            let validation = this.validateRow()
            if (validation) {
                const newRowData: ITagesordnungspunkt = this.createRowData(getRow);
                await updateKonfigTagesordnung(newRowData, handleTokenAccessFailure)
                let rowsData = this.state.rowsData
                this.setState({
                    data: rowsData,
                    editing: -1,
                    validateTagesordnungPunkt: false,
                    responiblePersonIdEdit: '',
                    nameEdit: '',
                    istBeschlussfassungVorgesehenEdit: false,
                    vordefinierterTypEdit: VordefinierterTyp.Allgemein,
                }, await this.getRows);
            }
        }
        this.setState({ isLoading: false })
    }

    handleCloseClick = (e) => {
        this.setState({
            editing: -1,
            addSubitem: false,
            validateTagesordnungPunkt: false,
            responiblePersonIdEdit: "",
            responiblePersonIdEditSubitem: "",
            nameEdit: '',
            istBeschlussfassungVorgesehenEdit: false,
            nameEditSubitem: '',
            istBeschlussfassungVorgesehenEditSubitem: false,
            vordefinierterTypEdit: VordefinierterTyp.Allgemein,
            vordefinierterTypEditSubitem: VordefinierterTyp.Allgemein
        });
    }

    calculateDauer = (row, hasSubitems, rowsData) => {
        if (row.parentItemId === null && hasSubitems) {
            return rowsData.filter((val) => val.parentItemId === row.id).reduce((acc, val) => acc + val.dauer!, 0)
        }
        return 0
    }

    disableUpDownBtns = () => {
        this.state.moveDownBtnRefs.forEach(btn => {
            if (btn) {
                btn.disabled = true;
            }
        });

        this.state.moveUpBtnRefs.forEach(btn => {
            if (btn) {
                btn.disabled = true;
            }
        });
    }

    enableUpDownBtns = () => {
        this.setState({ editing: -1 })
        if (this.props.disabled) {
            return;
        }
        this.state.moveDownBtnRefs.forEach(btn => {
            if (btn) {
                btn.disabled = false;
            }
        });

        this.state.moveUpBtnRefs.forEach(btn => {
            if (btn) {
                btn.disabled = false;
            }
        });
    }

    getRows = async () => {
        let items: ITagesordnungspunkt[] = []
        let subitems: ITagesordnungspunkt[] = []
        const response = await getTagesordnungPunktKonfig(handleTokenAccessFailure)
        const rowsDataApi = response.data
        this.setState({ rowsData: rowsDataApi }, () => {
            const mainItems = rowsDataApi?.filter((item) => item.parentItemId === null).sort((a, b) => { return a.sequenz - b.sequenz })
            mainItems?.forEach((item) => {
                items.push(item)
                rowsDataApi?.forEach((subitem) => {
                    if (subitem.parentItemId === item.id) {
                        subitems.push(subitem)
                    }
                })
                subitems.sort((a, b) => {
                    return a.sequenz - b.sequenz;
                })
                subitems.forEach((subitem) => items.push(subitem))
                subitems = []
            })
            this.setState({ rowsData: items })
        })
        this.setState({ isLoading: false })
    }

    getRowsSitzung = async () => {
        let items: ITagesordnungspunkt[] = []
        let subitems: ITagesordnungspunkt[] = []
        if (this.props.isSitzung) {
            const response = await getTagesordnungSitzungDto(this.props.isSitzung, handleTokenAccessFailure)
            let rowsDataApi = response.data
            this.setState({ rowsDataSitzung: rowsDataApi }, () => {
                const mainItems = rowsDataApi?.filter((item) => item.parentItemId === null).sort((a, b) => { return a.sequenz - b.sequenz })
                mainItems?.forEach((item) => {

                    items.push(item)
                    rowsDataApi?.forEach((subitem) => {
                        if (subitem.parentItemId === item.id) {
                            subitems.push(subitem)
                        }
                    })
                    subitems.sort((a, b) => {
                        return a.sequenz - b.sequenz;
                    })
                    subitems.forEach((subitem) => items.push(subitem))
                    subitems = []
                })
                this.setState({ rowsDataSitzung: items })
            })
        }
        this.setState({ isLoading: false })
    }

    subitemForm = (i, row) => {
        let getNextSequenz = this.props.isSitzung ? this.state.rowsDataSitzung.filter((val) => val.parentItemId === row.id) : this.state.rowsData.filter((val) => val.parentItemId === row.id)
        if (i === this.state.editing) {
            return <TableRow
                key={i}
            >
                <TableCell>
                    <TableCellLayout>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                            {row.sequenz + '.' + (getNextSequenz?.length + 1)}
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell>
                    <TableCellLayout style={{ width: "150px" }}>
                        {<Field><Dropdown listbox={{ style: { height: "fit-content", maxHeight: "200px", width: "200px" } }} value={vordefinierterTyp.find((u) => u.id === this.state.vordefinierterTypEditSubitem)?.type} style={{ minWidth: "130px" }} onOptionSelect={this.onSelectTypSubitem}>{vordefinierterTyp.map((u) => <Option key={u.id.toString()} value={u.id.toString()}>{u.type}</Option>)}</Dropdown></Field>}
                    </TableCellLayout>
                </TableCell>
                {this.state.verantwortlichChecked &&
                    <TableCell>
                        <TableCellLayout>
                            {<Field><Dropdown listbox={{ style: { height: "200px", width: "250px" } }} disabled={!this.state.mitglieders.length} style={{ minWidth: "150px" }} onOptionSelect={(_, v) => this.setState({ responiblePersonIdEditSubitem: v.optionValue! })}>{this.state.mitglieders.map((u) => <Option key={u.id.toString()} value={u.id.toString()}>{u.displayName}</Option>)}</Dropdown></Field>}
                        </TableCellLayout>
                    </TableCell>
                }
                <TableCell>
                    <TableCellLayout style={{ minWidth: "200px !important" }}>
                        {<Field><Input autoFocus={true} onChange={(e) => this.setState({ nameEditSubitem: e.target.value })} value={this.state.nameEditSubitem} style={{ minWidth: "200px" }} /></Field>}
                    </TableCellLayout>
                </TableCell>
                {this.state.dauerChecked && <TableCell>
                    <TableCellLayout>
                        {<Field><Input defaultValue='5' min={1} max={9999} onChange={(e) => this.setState({ dauerEditSubitem: this.handleDauerChange(e) })} type='number' style={{ width: "50px", padding: "0 0", }} /></Field>}
                    </TableCellLayout>
                </TableCell>}
                <TableCell >
                    <TableCellLayout>
                        {<Switch defaultChecked={false}
                            disabled={(this.state.vordefinierterTypEditSubitem === VordefinierterTyp.Organisatorisches || this.state.vordefinierterTypEditSubitem === VordefinierterTyp.ErgänzungenTO || this.state.vordefinierterTypEditSubitem === VordefinierterTyp.Verschiedenes || this.state.vordefinierterTypEditSubitem === VordefinierterTyp.NeuerSitzungstag)} checked={this.state.istBeschlussfassungVorgesehenEditSubitem} onChange={(e) => this.setState({ istBeschlussfassungVorgesehenEditSubitem: !this.state.istBeschlussfassungVorgesehenEdit })} />}
                    </TableCellLayout>
                </TableCell>
                {this.state.anlageChecked && <TableCell>
                    <TableCellLayout>
                        {<Switch defaultChecked={false} onChange={(e) => this.setState({ anlageEditSubitem: !this.state.anlageEdit })} />}
                    </TableCellLayout>
                </TableCell>}
                <TableCell>
                    <TableCellLayout>
                        {this.actionsCell(i, true, row, true)}
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        }
    }

    onSelectTyp = (_, v) => {
        this.setState({ vordefinierterTypEdit: parseInt(v.optionValue!) })
        const typ: number = parseInt(v.optionValue)
        const defaultValues = this.state.defaultTableValues

        if (typ === VordefinierterTyp.Allgemein) {
            this.setState({ nameEdit: '', istBeschlussfassungVorgesehenEdit: false })
            return
        }
        if (typ === VordefinierterTyp.NeuerSitzungstag) {
            this.setState({ nameEdit: 'Neuer Sitzungstag: Organisatorisches und Beschlussfähigkeit', istBeschlussfassungVorgesehenEdit: false })
            return
        }

        const findValue = defaultValues.find((v) => v.typ == typ)

        this.setState({ nameEdit: findValue!.name, istBeschlussfassungVorgesehenEdit: findValue!.mitBeschluss })
    }

    onSelectTypSubitem = (_, v) => {
        this.setState({ vordefinierterTypEditSubitem: parseInt(v.optionValue!) })
        const typ: number = parseInt(v.optionValue)
        const defaultValues = this.state.defaultTableValues
        if (typ === VordefinierterTyp.Allgemein) {
            this.setState({ nameEditSubitem: '', istBeschlussfassungVorgesehenEditSubitem: false })
            return
        }
        if (typ === VordefinierterTyp.NeuerSitzungstag) {
            this.setState({ nameEditSubitem: 'Neuer Sitzungstag: Organisatorisches und Beschlussfähigkeit', istBeschlussfassungVorgesehenEditSubitem: false })
            return
        }

        const findValue = defaultValues.find((v) => v.typ == typ)

        this.setState({ nameEditSubitem: findValue!.name, istBeschlussfassungVorgesehenEditSubitem: findValue!.mitBeschluss })
    }

    continueDialogConfirm = () => {
        this.setState({
            isDialogOpen: false,
            editing: this.state.clickedRow
        })
    }

    cancelDialogConfirm = () => {
        this.setState({
            isDialogOpen: false,
        })
    }

    saveDialogData = async (rowData) => {
        const getRow = this.state.rowsData?.find((row) => row.id === rowData.id)
        const getRowSitzung = this.state.rowsDataSitzung?.find((row) => row.id === rowData.id)
        this.setState({ validateTagesordnungPunkt: true })
        if (this.props.isSitzung) {
            let validation = this.validateRow()
            if (validation) {
                const newRowDataSitzung: ITagesordnungspunkt = this.createRowData(getRowSitzung);
                await updateTagesordnungDto(newRowDataSitzung, handleTokenAccessFailure)
                let rowsData = this.state.rowsDataSitzung

                this.setState({
                    data: rowsData,
                    editing: this.state.editing,
                    validateTagesordnungPunkt: false
                }, await this.getRowsSitzung);
            }
        } else {
            let validation = this.validateRow()
            if (validation) {
                const newRowData: ITagesordnungspunkt = this.createRowData(getRow);
                await updateKonfigTagesordnung(newRowData, handleTokenAccessFailure)
                let rowsData = this.state.rowsData

                this.setState({
                    data: rowsData,
                    editing: this.state.editing,
                    validateTagesordnungPunkt: false
                }, await this.getRows);
            }
        }

    }

    newRowForm = (i) => {
        let nextSequenz = this.props.isSitzung ? this.state.rowsDataSitzung.filter((val) => val.parentItemId === null).length : this.state.rowsData.filter((val) => val.parentItemId === null).length

        return <TableRow
            key={i}
        >
            <TableCell>
                <TableCellLayout>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                        {nextSequenz + 1}
                    </div>
                </TableCellLayout>
            </TableCell>
            <TableCell>
                <TableCellLayout style={{ width: "150px" }}>
                    {<Field><Dropdown listbox={{ style: { height: "fit-content", maxHeight: "200px", width: "200px" } }} defaultValue={vordefinierterTyp[0].type} value={vordefinierterTyp.find((u) => u.id === this.state.vordefinierterTypEdit)?.type} style={{ minWidth: "130px" }} onOptionSelect={this.onSelectTyp}>{vordefinierterTyp.map((u) => <Option key={u.id.toString()} value={u.id.toString()}>{u.type}</Option>)}</Dropdown></Field>}
                </TableCellLayout>
            </TableCell>
            {this.state.verantwortlichChecked &&
                <TableCell>
                    <TableCellLayout>
                        {<Field><Dropdown listbox={{ style: { height: "200px", width: "250px" } }} disabled={!this.state.mitglieders.length} style={{ minWidth: "150px" }} onOptionSelect={(_, v) => this.setState({ responiblePersonIdEdit: v.optionValue! })}>{this.state.mitglieders.map((u) => <Option key={u.id.toString()} value={u.id.toString()}>{u.displayName}</Option>)}</Dropdown></Field>}
                    </TableCellLayout>
                </TableCell>
            }
            <TableCell>
                <TableCellLayout style={{ minWidth: "200px !important" }}>
                    {<Field><Input autoFocus={true} onChange={(e) => this.setState({ nameEdit: e.target.value })} value={this.state.nameEdit} style={{ minWidth: "200px" }} /></Field>}
                </TableCellLayout>
            </TableCell>
            {this.state.dauerChecked && <TableCell>
                <TableCellLayout>
                    {<Field><Input defaultValue='5' onChange={(e) => this.setState({ dauerEdit: parseInt(e.target.value) })} type='number' style={{ width: "50px", padding: "0 0", }} /></Field>}
                </TableCellLayout>
            </TableCell>}
            <TableCell >
                <TableCellLayout>
                    {<Switch
                        disabled={(this.state.vordefinierterTypEdit === VordefinierterTyp.Organisatorisches || this.state.vordefinierterTypEdit === VordefinierterTyp.ErgänzungenTO || this.state.vordefinierterTypEdit === VordefinierterTyp.Verschiedenes || this.state.vordefinierterTypEdit === VordefinierterTyp.NeuerSitzungstag)}
                        defaultChecked={false} checked={this.state.istBeschlussfassungVorgesehenEdit} onChange={(e) => this.setState({ istBeschlussfassungVorgesehenEdit: !this.state.istBeschlussfassungVorgesehenEdit })} />}
                </TableCellLayout>
            </TableCell>
            {this.state.anlageChecked && <TableCell>
                <TableCellLayout>
                    {<Switch defaultChecked={false} onChange={(e) => this.setState({ anlageEdit: !this.state.anlageEdit })} />}
                </TableCellLayout>
            </TableCell>}
            <TableCell>
                <TableCellLayout>
                    <div
                        className='editFinalizationBtnGroup'
                    >
                        <Button appearance='transparent' icon={<span className="icon-check-color"><CheckIcon /></span>} title='Check' key={'check'} onClick={e => { this.setState({ isLoading: true }, () => this.addNewRow()) }}></Button>
                        <Button appearance='transparent' icon={<span className="icon-close-color"><CloseIcon /></span>} title='Close' key={'close'} onClick={e => this.setState({ addNewRow: false, editing: -1 })}></Button>
                    </div>
                </TableCellLayout>
            </TableCell>
        </TableRow>
    }

    renderColumns(column, i) {
        const { id, label } = column;
        if (
            (id !== "dauer" && id !== "hasAttachments" && id !== "responsiblePersonId") ||
            (id === "dauer" && this.state.dauerChecked) ||
            (id === "hasAttachments" && this.state.anlageChecked) ||
            (id === "responsiblePersonId" && this.state.verantwortlichChecked)
        ) {
            return <TableHeaderCell
                key={i}
                style={{
                    fontWeight: "bold",
                    width: column.id === "name" ? "200px" : column.id === "responsiblePersonId" || column.id === "vordefinierterTyp" ? "140px" : column.id === 'dauer' ? "35px" : column.id !== "actions" ? "60px" : this.state.editing === -1 ? "50px" : "120px",
                    minWidth: column.id === "name" ? "200px" : column.id === "responsiblePersonId" || column.id === "vordefinierterTyp" ? "140px" : column.id === 'dauer' ? "35px" : column.id !== "actions" ? "60px" : this.state.editing === -1 ? "50px" : "120px",
                    maxWidth: column.id === "name" ? "1000px" : column.id === "responsiblePersonId" || column.id === "vordefinierterTyp" ? "140px" : column.id === 'dauer' ? "35px" : column.id !== "actions" ? "60px" : this.state.editing === -1 ? "50px" : "120px",
                }}>{label}</TableHeaderCell>;
        }
    }

    updateCheckedState = async (type, value) => {
        this.setState({ isLoading: true })
        if (this.props.isSitzung) {
            const agenda = await getAgenda(this.props.isSitzung)
            const agendaId = agenda.data[0].id
            const typeField = type.replace("OnTagesordnung", "")
            await patchTagesordnungDto(agendaId, typeField, value, handleTokenAccessFailure)
            if (agenda.data.tagesordnungstatus === MeetingFormStepStatus.Processed) {
                await patchTagesordnungDto(agendaId, "tagesordnungstatus", MeetingFormStepStatus.NeedsReprocessing, handleTokenAccessFailure)
            }
            this.props.dataHasChanged(true)
        } else {
            await patchCheckbox(this.props.gremiumDetailId!, type, value, handleTokenAccessFailure)
        }
        this.setState({ isLoading: false })
    }

    handleDauerChange = (e) =>{
        const value = Math.abs(parseInt(e.target.value))
        if(value && value > 5){
            return value
        }
        else{
            return 5
        }
    }

    renderRow = (i, row, type) => {
        const rowsData = this.props.isSitzung ? this.state.rowsDataSitzung : this.state.rowsData
        const mainItemSequenz = rowsData!.find((val) => val.id === row.parentItemId)
        const hasSubitems = rowsData.find((val) => val.parentItemId === row.id)
        return <>
            <TableRow
                key={i}
                appearance={type === "subitem" ? "brand" : "none"}
            >
                <TableCell >
                    <TableCellLayout >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", fontSize: type === "subitem" ? "1.1rem" : "", paddingLeft: type === "subitem" ? 10 : "" }}>
                            {type === "subitem" ? `${mainItemSequenz?.sequenz}.${row.sequenz}` : row.sequenz}
                            <div style={{ display: "flex", alignItems: "center", marginLeft: 5 }}>
                                <Button style={{ minWidth: type === "subitem" ? 10 : "", maxWidth: type === "subitem" ? 20 : "" }} disabled={this.props.disabled || this.state.editing !== -1} appearance='transparent' ref={ref => { this.state.moveUpBtnRefs[i] = ref }} icon={<span><ArrowUpIcon /></span>} onClick={e => this.moveRowUp(e, i)} />
                                <Button style={{ minWidth: type === "subitem" ? 10 : "", maxWidth: type === "subitem" ? 20 : "" }} disabled={this.props.disabled || this.state.editing !== -1} appearance='transparent' ref={ref => { this.state.moveDownBtnRefs[i] = ref }} icon={<span><ArrowDownIcon /></span>} onClick={e => this.moveRowDown(e, i)} />
                            </div>
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell>
                    <TableCellLayout style={{ width: "150px" }}>
                        {this.state.editing === i && !this.state.addSubitem ? <Field><Dropdown listbox={{ style: { height: "fit-content", maxHeight: "200px", width: "200px" } }} value={vordefinierterTyp.find((u) => u.id === this.state.vordefinierterTypEdit)?.type} style={{ minWidth: "130px" }} onOptionSelect={this.onSelectTyp}>{vordefinierterTyp.map((u) => <Option key={u.id.toString()} value={u.id.toString()}>{u.type}</Option>)}</Dropdown></Field> : vordefinierterTyp.find((u) => u.id === row.vordefinierterTyp)?.type}
                    </TableCellLayout>
                </TableCell>
                {this.state.verantwortlichChecked &&
                    <TableCell>
                        <TableCellLayout style={{ overflow: "hidden" }}>
                            {this.state.editing === i && !this.state.addSubitem ? <Field><Dropdown listbox={{ style: { height: "fit-content", maxHeight: "200px", width: "250px" } }} disabled={!this.state.mitglieders.length} value={this.state.mitglieders.find((u) => u.id === row.responsiblePersonId)?.displayName} style={{ minWidth: "120px" }} onOptionSelect={(_, v) => this.setState({ responiblePersonIdEdit: v.optionValue! })}>{this.state.mitglieders.map((u) => <Option key={u.id.toString()} value={u.id.toString()}>{u.displayName}</Option>)}</Dropdown></Field> : this.state.mitglieders.find((u) => u.id === row.responsiblePersonId)?.displayName}
                        </TableCellLayout>
                    </TableCell>
                }
                <TableCell style={{ minWidth: "200px !important" }}>
                    <TableCellLayout >
                        {this.state.editing === i && !this.state.addSubitem ? <Field validationState={!this.state.nameEdit && this.state.validateTagesordnungPunkt ? "error" : "none"}><Input autoFocus={true} defaultValue={row.name} value={this.state.nameEdit} onChange={(e) => this.setState({ nameEdit: e.target.value })} style={{ minWidth: "200px" }} /></Field> : row.name}
                    </TableCellLayout>
                </TableCell>
                {this.state.dauerChecked && <TableCell>
                    <TableCellLayout>
                        {this.state.editing === i && !hasSubitems && !this.state.addSubitem ? <Field><Input min={1} max={9999} defaultValue={row.dauer} onChange={(e) => this.setState({ dauerEdit: this.handleDauerChange(e) })} type='number' style={{ width: "50px", padding: "0 0", }} /></Field> : row.parentItemId !== null ? row.dauer :
                            hasSubitems ? this.calculateDauer(row, hasSubitems, rowsData) : row.dauer
                        }
                    </TableCellLayout>
                </TableCell>}
                <TableCell >
                    <TableCellLayout>
                        {this.state.editing === i && !this.state.addSubitem ? <Switch defaultChecked={this.state.istBeschlussfassungVorgesehenEdit}
                            disabled={(this.state.vordefinierterTypEdit === VordefinierterTyp.Organisatorisches || this.state.vordefinierterTypEdit === VordefinierterTyp.ErgänzungenTO || this.state.vordefinierterTypEdit === VordefinierterTyp.Verschiedenes || this.state.vordefinierterTypEdit === VordefinierterTyp.NeuerSitzungstag)}
                            checked={this.state.istBeschlussfassungVorgesehenEdit} onChange={(e) => this.setState({ istBeschlussfassungVorgesehenEdit: !this.state.istBeschlussfassungVorgesehenEdit })} /> : row.istBeschlussfassungVorgesehen ? "Ja" : "Nein"}
                    </TableCellLayout>
                </TableCell>
                {this.state.anlageChecked && <TableCell>
                    <TableCellLayout>
                        {this.state.editing === i && !this.state.addSubitem ? <Switch defaultChecked={this.state.anlageEdit} onChange={(e) => this.setState({ anlageEdit: !this.state.anlageEdit })} /> : row.hasAttachments ? "Ja" : "Nein"}
                    </TableCellLayout>
                </TableCell>}
                <TableCell>
                    <TableCellLayout>
                        {!this.props.disabled && !this.state.addSubitem && this.actionsCell(i, type === "subitem" ? true : false, row, hasSubitems)}
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        </>
    }

    handleTokenAccessFailure = (error: string) => {
        alert(error);
    };

    resetToDefaultTable = async () => {
        this.state.confirmDialogRef.current.showConfirmDialog(ConfirmResetToDefaultTagesordnung, async () => {
            this.setState({ isLoading: true })
            if (this.props.isSitzung) {
                const resp = await getTagesordnungPunktKonfigGremium(this.props.gremiumDetailId!, handleTokenAccessFailure)
                const defaultData: NewTagesordnungspunkt[] = resp.data
                const filterSubitems = this.state.rowsDataSitzung.filter((t) => t.parentItemId)
                if (filterSubitems.length > 0) {
                    for (const t of filterSubitems) {
                        await deleteRowTagesordnung(t.id, this.handleTokenAccessFailure)
                    }
                }
                for (const t of this.state.rowsDataSitzung) {
                    if (!t.parentItemId) {
                        await deleteRowTagesordnung(t.id, this.handleTokenAccessFailure)
                    }
                }
                const insertPromises = defaultData.map(async (t) => {
                    const data: NewTagesordnungspunkt = {
                        name: t.name,
                        sequenz: t.sequenz,
                        dauer: t.dauer,
                        istBeschlussfassungVorgesehen: t.istBeschlussfassungVorgesehen,
                        responsiblePersonId: t.responsiblePersonId || null,
                        parentItemId: t.parentItemId || null,
                        hasAttachments: t.hasAttachments,
                        vordefinierterTyp: t.vordefinierterTyp
                    }
                    const agenda = await getAgenda(this.props.isSitzung!)
                    const agendaId = agenda.data[0].id
                    data.tagesordnungId = agendaId
                    await insertTagesordnungpunktDto(data, this.handleTokenAccessFailure)
                })
                await Promise.all(insertPromises)
            } else {
                const resp = await getTagesordnungPunktKonfigDefaults(handleTokenAccessFailure)
                const defaultData: ITagesordnungspunkt[] = resp.data
                const filterSubitems = this.state.rowsData.filter((t) => t.parentItemId)
                if (filterSubitems.length > 0) {
                    for (const t of filterSubitems) {
                        await deleteRowKonfigTagesordnung(t.id, this.handleTokenAccessFailure)
                    }
                }
                for (const t of this.state.rowsData) {
                    if (!t.parentItemId) {
                        await deleteRowKonfigTagesordnung(t.id, this.handleTokenAccessFailure)
                    }
                }

                const insertPromises = defaultData.map(async (t) => {
                    await insertTagesordnungpunktVorlageDto(t, this.handleTokenAccessFailure)
                })
                await Promise.all(insertPromises);

            }
            if (this.props.isSitzung) {
                await this.getRowsSitzung()
            } else {
                await this.getRows()
            }
        })


    }

    render() {
        const tableColumns: TableColumnType[] = [
            {
                id: "sequenz",
                label: "Nr.",
            },
            {
                id: "vordefinierterTyp",
                label: "Typ",
            },
            {
                id: "responsiblePersonId",
                label: "Verantwortlich",
            },
            {
                id: 'name',
                label: 'Tagesordnungspunkt',
            },
            {
                id: "dauer",
                label: "Dauer in Min",
            },
            {
                id: "istBeschlussfassungVorgesehen",
                label: "Mit Beschluss",
            },
            {
                id: "hasAttachments",
                label: "Anlage",
            },
            {
                id: "actions",
                label: '',
            },
        ];

        let rowsToRender = this.props.isSitzung ? this.state.rowsDataSitzung : this.state.rowsData

        return (
            <div className={this.props.disabled ? 'input-list-wrapper disabled' : 'input-list-wrapper'} >
                <ConfirmDialog
                    ref={this.state.confirmDialogRef} />
                {!this.props.disabled &&
                    <div style={{ margin: "15px 0" }}>
                        <span>Anzeigen / Ausblenden: </span>
                        <Checkbox id={"dauer"} label={"Dauer in Min."} checked={this.state.dauerChecked} onChange={(e) => { this.setState({ dauerChecked: !this.state.dauerChecked, initialUpdate: false }); this.updateCheckedState("showDauerOnTagesordnung", e.target.checked) }}></Checkbox>
                        <Checkbox id={"verantwortlich"} label={"Verantwortlich"} checked={this.state.verantwortlichChecked} onChange={(e) => { this.setState({ verantwortlichChecked: !this.state.verantwortlichChecked, initialUpdate: false }); this.updateCheckedState("showResponsiblePersonOnTagesordnung", e.target.checked) }}></Checkbox>
                        <Checkbox id={"anlage"} label={"Anlage"} checked={this.state.anlageChecked} onChange={(e) => { this.setState({ anlageChecked: !this.state.anlageChecked, initialUpdate: false }); this.updateCheckedState("showHasAttachmentsOnTagesordnung", e.target.checked) }}></Checkbox><Tooltip relationship='label' content="Wenn Sie angeben, dass eine Anlage existiert, wird in der Tagesordnung ersichtlich, dass im jeweiligen Sitzungsordner unter Dateien weitere Unterlagen zu diesem Tagesordnungspunkt liegen."><span><InfoIcon /></span></Tooltip>
                    </div>
                }

                {/* <input type="hidden" className="agenda__id" value={this.props.id} /> */}
                <div style={{ marginBottom: 10 }} className="konfiguration__save-btn-wrapper" >
                    <Button
                        icon={<span style={{ fontSize: "20px" }}><AddIcon /></span>}
                        onClick={this.addNewRow}
                        appearance='secondary'
                        disabled={this.props.disabled || this.state.editing !== -1}
                    >Hinzufügen</Button>
                    <ResetToDefaultButton disabledState={this.props.disabled || this.state.editing !== -1} onClick={this.resetToDefaultTable} />
                </div>
                <Divider />
                <div className='input-list-body'>
                    {this.state.isLoading ? <Spinner
                        style={{
                            width: "100%",
                            height: "150px",
                            position: "relative",
                            top: "0",
                            left: "0",
                        }}
                        className="loader"
                        label={LoaderMessage}
                    /> : <div className='table-container'><Table
                        sortable
                        className='table-container-overflow'
                        style={{ marginBottom: 20 }}
                        ref={this.state.tableRef}
                    >
                        <TableHeader>
                            <TableRow key={'columns'}>
                                {tableColumns.map((column, i) => { return this.renderColumns(column, i) }
                                )}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {rowsToRender!.map((row, i) => (<>
                                {this.state.editing === i && this.state.isDialogOpen && <KonfigurationSpeichernModal
                                    handleCloseDialog={this.cancelDialogConfirm}
                                    handleContinueDialog={this.continueDialogConfirm}
                                    handleSaveAndContinueDialog={this.continueDialogConfirm}
                                    isSavingDialogOpen={this.state.isDialogOpen}
                                    saveData={() => this.saveDialogData(row)}
                                />}
                                {!row.parentItemId &&
                                    this.renderRow(i, row, "item")
                                }
                                {row.parentItemId &&
                                    this.renderRow(i, row, "subitem")
                                }
                                {this.state.addSubitem && this.subitemForm(i, row)
                                }
                                {this.state.addNewRow && (i + 1) === this.state.newRowIndex && this.newRowForm(i)
                                }
                            </>
                            ))}
                            {rowsToRender.length === 0 && this.state.addNewRow && this.newRowForm(1)}
                        </TableBody >
                    </Table>
                    </div>
                    }
                </div>
            </div >
        );
    }
}

export default TagesordnungInputList;