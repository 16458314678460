import React from "react";
import {
  Text,
  Input,
  Field,
  Tooltip,
  Dropdown,
  Combobox,
  Option,
  Persona,
  FieldProps,
} from "@fluentui/react-components";
import { DatePicker } from '@fluentui/react-datepicker-compat'
import { TimePicker, TimePickerErrorType, TimePickerProps } from '@fluentui/react-timepicker-compat'
import { Guid } from "guid-typescript";
import {
  DateInvalidErrorMessage,
  DateNotInRangeErrorMessage,
  RequiredFieldErrorMessage,
  SitzungLongNameErrorMessage,
  SitzungNameIllegalCharsErrorMessage,
  SitzungSameNameErrorMessage,
} from "../../../constants/textLabels";
import { userHasPermission } from "../../../Helpers/permissionHelper";
import { StatusCodes } from "http-status-codes";
import { getSitzungsräume } from "../../../api/sitzung-raum-api";
import { handleTokenAccessFailure } from "../../../Helpers/apiHelper";
import { getGremiumKonfig, patchProtokollSitzung } from "../../../api/konfiguration-api";
import ISitzung from "../../../models/sitzung/create-sitzung";
import { clone, cloneDeep } from "lodash";
import RequiredSign from "../../common/required-sign/required-sign";
import ISiztungFormBaseProps from "./sitzung-form-base-props";
import ISitzungFormBaseState from "./sitzung-form-base-state";
import { getMeeting, patchMeeting, postMeeting, putMeeting } from "../../../api/meeting-dto-api";
import { sitzungFormats, sitzungTypes } from "../../../constants/constants";
import { IMeetingFormBaseFunctions } from "./sitzung-form-base-functions";
import { MeetingFormStepStatus } from "../../../enums/meeting-form-step-status.enum";
import "./meeting-form.scss"
import InfoBox from "../../common/info-box/infoBox";
import { getInvitation, patchInvitation } from "../../../api/invitations-dto-api";
import IRoom from "../../../models/rooms/rooms";
import { getAttendanceList, patchAttendanceList } from "../../../api/attendance-list-dto-api";
import { getAgenda, getSitzungProtokoll, patchAgenda } from "../../../api/agenda-dto-api";
import { InfoIcon } from "../../../utils/icons";
import { formatDate } from "../../../utils/formatDate";

declare type Hour = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24;

interface IMeetingDetailsProps extends ISiztungFormBaseProps {
  allSitzungs: { id: string, name: string; start: Date }[];
  setMeetingId(meetingId: string): void;
  setMeetingTimes(meetingStart: Date, meetingEnd: Date): void;
  setMeetingTitle(title: string);
  setIsFormValid(isValid: boolean);
  resetPhase: Function
}

interface IMeetingDetailsState extends ISitzungFormBaseState {
  meeting: ISitzung;
  meetingInitial: ISitzung;
  meetingPrev: ISitzung;
  roomOptions: IRoom[];
  errorMsgName: string;
  isNameValid: boolean;
  typingTimer: NodeJS.Timeout | undefined;
  roomInput: string;
  invitationId: string;
  errorType: TimePickerErrorType;
  errorTypeEnd: TimePickerErrorType;
  errorTimeStart: boolean;
  errorTimeEnd: boolean;
  selectedTime: Date | null;
  timePickerValue: string;
  selectedTimeEnd: Date | null;
  timePickerValueEnd: string;
  roomDisplayName: string
}

class MeetingDetails extends React.Component<
  IMeetingDetailsProps,
  IMeetingDetailsState
> implements IMeetingFormBaseFunctions {
  constructor(props: any) {
    super(props);

    let tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);


    this.state = {
      roomDisplayName: '',
      selectedTime: null,
      timePickerValue: '',
      selectedTimeEnd: null,
      timePickerValueEnd: '',
      errorTimeStart: false,
      errorTimeEnd: false,
      errorType: 'required-input',
      errorTypeEnd: 'required-input',
      invitationId: '',
      isLoading: false,
      showLoader: false,
      meeting: {
        id: this.props.meetingId,
        name: "",
        start: tomorrow,
        end: new Date(tomorrow.getTime() + 60 * 60000),
        sitzungsart: 1,
        room: '',
        sitzungsformat: 1,
        teamId: Guid.createEmpty()
      },
      meetingInitial: {
        id: this.props.meetingId,
        name: "",
        start: tomorrow,
        end: new Date(tomorrow.getTime() + 60 * 60000),
        sitzungsart: 1,
        room: '',
        sitzungsformat: 1,
        teamId: Guid.createEmpty()
      },
      meetingPrev: {
        id: this.props.meetingId,
        name: "",
        start: tomorrow,
        end: new Date(tomorrow.getTime() + 60 * 60000),
        sitzungsart: 1,
        room: '',
        sitzungsformat: 1,
        teamId: Guid.createEmpty()
      },
      errorMsgName: "",
      isNameValid: true,
      roomOptions: [{ value: '', displayName: "", isCustom: false, availability: 0 }],
      typingTimer: undefined,
      roomInput: "",
    };
  }

  componentDidMount = async () => {
    this.props.setIsLoading(true);
    await this.loadData();
    this.props.setSaveAndResetFunctions(this.saveDraft);
    //this.props.setDataHasChanged(false);
    this.loadMeetingRooms();
    // await this.props.loadMeetingStepStatuses();
    if (this.state.meeting.sitzungsformat === 2) {
      this.props.setIsFormValid(true)
    }
    let meetingDetails
    if (this.props.meetingId) {
      const meetingDetailsResponse = await getMeeting(this.props.meetingId);
      meetingDetails = meetingDetailsResponse.data.name
    }

    if (!meetingDetails) {
      meetingDetails = ''
    }

    if (
      this.props.allSitzungs.findIndex(
        (s) =>
          (s.name.toLowerCase() === meetingDetails.toLowerCase()) && (new Date(s.start).setHours(0, 0, 0, 0) === new Date(this.state.meeting.start).setHours(0, 0, 0, 0)) && this.state.meeting.id !== s.id
      ) !== -1
    ) {
      this.setState({ isNameValid: false, errorMsgName: SitzungSameNameErrorMessage })
    }
    this.validateForm()
    this.props.setIsLoading(false);
  }

  loadData = async () => {
    if (this.props.meetingId != Guid.EMPTY) {
      await this.loadMeeting();
    } else {
      await this.loadTeamsIdFromConfig();
    }
  }

  loadMeeting = async () => {
    this.loadMeetingRooms();
    const response = await getMeeting(this.props.meetingId);
    if (response.status === StatusCodes.OK && response.data) {
      //Set begin and end time
      const now = new Date()
      let offset = now.getTimezoneOffset()
      offset = offset * 60000 //Calculate the offset from UTC to the current timezone and convert it to millies
      const offsetMillis = -offset //Minus because we want the opposite operation when calculating to UTC
      let start = new Date(response.data.start);
      let end = new Date(response.data.end);

      const utcStart = start.getTime();
      const utcEnd = end.getTime();

      const utcPlusOneTimestamp = utcStart + offsetMillis;
      const utcPlusOneTimestampEnd = utcEnd + offsetMillis;

      const startDate = new Date(utcPlusOneTimestamp);
      const endDate = new Date(utcPlusOneTimestampEnd);

      response.data.start = startDate;
      response.data.end = endDate;

      const calcMinutes = startDate.getMinutes() < 10 ? '0' + startDate.getMinutes() : startDate.getMinutes();
      const calcHours = startDate.getHours() < 10 ? '0' + startDate.getHours() : startDate.getHours();
      const calcMinutesEnd = endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes();
      const calHoursEnd = endDate.getHours() < 10 ? '0' + endDate.getHours() : endDate.getHours();

      this.setState({
        meeting: response.data,
        meetingInitial: cloneDeep(response.data),
        meetingPrev: cloneDeep(response.data),
        timePickerValue: calcHours + ':' + calcMinutes,
        timePickerValueEnd: calHoursEnd + ':' + calcMinutesEnd,
      });
      this.props.setMeetingTimes(response.data.start, response.data.end);
      this.props.setMeetingTitle(response.data.name);
    }
  };

  loadMeetingRooms = async () => {
    let response = await getSitzungsräume(this.state.meeting.start.toISOString(), this.state.meeting.end.toISOString(), handleTokenAccessFailure);
    if (response.status === StatusCodes.OK && response.data) {
      const roomNameFind = (this.state.meeting.room && response.data.find((r) => r.value === this.state.meeting.room))
      let roomName = this.state.roomInput
      if (roomNameFind) {
        roomName = roomNameFind.displayName
      }
      this.setState({
        roomOptions: [
          { id: Guid.parse(Guid.EMPTY), name: "" },
          ...response.data,
        ],
        roomInput: roomName,
        roomDisplayName: roomName
      });
    }
  };

  loadTeamsIdFromConfig = async () => {
    const gremiumResponse = await getGremiumKonfig(localStorage.getItem("selectedAuschuss")!, handleTokenAccessFailure);
    if (
      gremiumResponse.status === StatusCodes.OK &&
      gremiumResponse.data.defaultTeamId
    ) {
      let meeting = this.state.meeting;
      meeting.teamId = gremiumResponse.data.defaultTeamId;

      const startDate = meeting.start
      const endDate = meeting.end

      const calcMinutes = startDate.getMinutes() < 10 ? '0' + startDate.getMinutes() : startDate.getMinutes();
      const calcHours = startDate.getHours() < 10 ? '0' + startDate.getHours() : startDate.getHours();
      const calcMinutesEnd = endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes();
      const calHoursEnd = endDate.getHours() < 10 ? '0' + endDate.getHours() : endDate.getHours();
      this.setState({
        meeting: meeting,
        timePickerValue: calcHours + ':' + calcMinutes,
        timePickerValueEnd: calHoursEnd + ':' + calcMinutesEnd,
      });
    }
  };

  onTypeChange = (e, v) => {
    let meeting = this.state.meeting;
    let meetingPrev = this.state.meetingPrev;
    const newType = sitzungTypes.find((t) => t.name == v.value);
    const newTypeId = newType ? newType.id : 0;
    const typePrev = meeting.sitzungsart;
    meeting.sitzungsart = newTypeId;
    meetingPrev.sitzungsart = typePrev;
    this.setState({ meeting: meeting, meetingPrev: meetingPrev }, () => {
      if (this.props.meetingId != Guid.EMPTY) {
        this.saveDraft("sitzungsart", newTypeId);
      }
    });
  };

  onNameChange = (e) => {
    let meeting = this.state.meeting;
    let meetingPrev = this.state.meetingPrev;
    const name: string = e.target.value;
    const namePrev = this.state.meeting.name;
    let isNameValid = true;
    let errorMsgName = "";

    //Check name existing
    if (
      this.props.allSitzungs.findIndex(
        (s) =>
          (s.name.toLowerCase() == name.toLowerCase()) && (new Date(s.start).setHours(0, 0, 0, 0) === new Date(this.state.meeting.start).setHours(0, 0, 0, 0)) && this.state.meeting.id !== s.id
      ) !== -1
    ) {
      isNameValid = false;
      errorMsgName += SitzungSameNameErrorMessage;
    }
    //Check if it is longer then 50 (39) chars
    if (name.length > 39) {
      isNameValid = false;
      errorMsgName += SitzungLongNameErrorMessage;
    }
    //Check if it is containing ilegal special chars
    if (/[~#%&*{}+/\:<>?|.'"]/.test(name) == true) {
      isNameValid = false;
      errorMsgName += SitzungNameIllegalCharsErrorMessage;
    }
    if (!this.state.typingTimer) {
      meetingPrev.name = namePrev;
      this.setState({ meetingPrev: meetingPrev });
    }
    meeting.name = name;
    this.setState(
      {
        meeting: meeting,
        isNameValid: isNameValid,
        errorMsgName: errorMsgName
      },
      () => {
        if (this.state.typingTimer) {
          clearTimeout(this.state.typingTimer);
        }
        this.setState({
          typingTimer: setTimeout(() => {
            if (this.props.meetingId != Guid.EMPTY) {
              this.saveDraft("name", name);
            }
          }, 1000),
        });
        this.validateForm()
      }
    );
  };

  setNewEndTime = async (timeHours, timeMinutes) => {
    const start = clone(this.state.meeting.start);
    const end = clone(this.state.meeting.end);

    const diff = this.timeDiff(start, end);

    const newStart = new Date(start);
    newStart.setHours(timeHours);
    newStart.setMinutes(timeMinutes);

    const newEnd = new Date(newStart.getTime() + diff.hours * 3600000 + diff.minutes * 60000);

    const calcMinutes = newEnd.getMinutes() < 10 ? '0' + newEnd.getMinutes() : newEnd.getMinutes();
    const timePickerValueEnd = newEnd.getHours() + ":" + calcMinutes;

    if(this.props.meetingId && this.props.meetingId !== Guid.createEmpty().toString()){
  await patchMeeting(this.props.meetingId, 'end', newEnd);
  await patchMeeting(this.props.meetingId, 'start', newStart);
}
    this.setState((prevState) => ({
      ...prevState,
      timePickerValueEnd: timePickerValueEnd,
      selectedTimeEnd: newEnd,
      meeting: {
        ...prevState.meeting,
        end: newEnd,
        start: newStart
      },
    }));
  };


  timeDiff = (startTime, endTime) => {
    let timeDifference = endTime - startTime;

    let hours = Math.floor(timeDifference / (1000 * 60 * 60));
    let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    return { hours, minutes };
  }

  onStartChange = (e) => {
    let startDate = e
    if (!Date.parse(startDate)) {
      let tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      startDate = tomorrow
    }
    let meeting = this.state.meeting;
    let meetingPrev = this.state.meetingPrev;
    this.setNewEndDate(startDate, meeting.start)
    startDate.setHours(meetingPrev.start.getHours())
    startDate.setMinutes(meetingPrev.start.getMinutes())
    const start: Date = startDate;
    const startPrev = meeting.start;
    meeting.start = start;
    meetingPrev.start = startPrev;

    this.props.setIsFormValid(true)
    if (e.value < new Date()) {
      this.props.setIsFormValid(false)
    }
    this.setState({ meeting: meeting, meetingPrev: meetingPrev }, () => {
      if (this.props.meetingId != Guid.EMPTY) {
        this.saveDraft("start", start);
      }
    });

    let isNameValid = true;
    let errorMsgName = "";
    if (
      this.props.allSitzungs.findIndex(
        (s) =>
          (s.name.toLowerCase() == this.state.meeting.name.toLowerCase()) && (new Date(s.start).setHours(0, 0, 0, 0) === new Date(this.state.meeting.start).setHours(0, 0, 0, 0)) && this.state.meeting.id !== s.id
      ) !== -1
    ) {
      isNameValid = false;
      errorMsgName += SitzungSameNameErrorMessage;
    }

    this.setState(
      {
        isNameValid: isNameValid,
        errorMsgName: errorMsgName
      }, this.loadMeetingRooms)
  };

  dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  setNewEndDate = (start, prevStart) => {
    let endDate = this.state.meeting.end
    const diff = this.dateDiffInDays(prevStart, endDate)
    if (diff === 0) {
      endDate.setDate(start.getDate())
      endDate.setMonth(start.getMonth())
      endDate.setFullYear(start.getFullYear())
    } else {
      endDate.setMonth(start.getMonth())
      endDate.setFullYear(start.getFullYear())
      endDate.setDate(start.getDate() + diff)
    }
  }

  onEndChange = (e) => {
    let endDate = e
    if (!Date.parse(endDate)) {
      let tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      endDate = tomorrow
    }
    let meeting = this.state.meeting;
    let meetingPrev = this.state.meetingPrev;

    endDate.setHours(meeting.end.getHours())
    endDate.setMinutes(meeting.end.getMinutes())
    const end: Date = endDate;
    const endPrev = meeting.end;
    meeting.end = end;
    meetingPrev.end = endPrev;
    if (e.value - this.state.meeting.start.getTime() < 60000) {
      this.props.setIsFormValid(false)
    }
    this.setState({ meeting: meeting, meetingPrev }, () => {
      if (this.props.meetingId != Guid.EMPTY) {
        this.saveDraft("end", end);
      }
      this.loadMeetingRooms()
    });
  };

  onFormatChange = (e, v) => {
    let meeting = this.state.meeting;
    let meetingPrev = this.state.meetingPrev;
    const format = sitzungFormats.find((format) => format.name == v.optionValue);
    const formatId = format ? format.id : 0;
    const formatPrev = meeting.sitzungsformat;
    meeting.sitzungsformat = formatId;
    meetingPrev.sitzungsformat = formatPrev;
    // Clear room if meeting format online
    const emptyRoom = ''
    if (formatId === 2) {
      const roomPrev = meeting.room;
      meeting.room = emptyRoom;
      meetingPrev.room = roomPrev;
      this.props.setIsFormValid(true)
    }
    this.setState({ meeting: meeting, meetingPrev: meetingPrev }, async () => {
      if (this.props.meetingId != Guid.EMPTY) {
        await this.saveDraft("sitzungsformat", formatId);
        if (formatId === 2) {
          await this.saveDraft("room", emptyRoom);
        }
      }
      this.validateForm()
    });
    if (this.props.meetingStepStatus === MeetingFormStepStatus.Processed) {
      this.resetToPhaseOne()
    }
  };

  resetToPhaseOne = async () => {
    const getAttendanceListResponse = await getAttendanceList(
      this.props.meetingId, handleTokenAccessFailure
    );
    const { data } = await getSitzungProtokoll(this.props.meetingId);
    const agenda = await getAgenda(this.props.meetingId)
    let invId
    if (!this.state.invitationId) {
      const resp = await getInvitation(this.state.meeting.id!.toString(), handleTokenAccessFailure)
      if (resp.data.length) {
        invId = resp.data[0].id
        this.setState({ invitationId: resp.data[0].id })
      }
    } else {
      invId = this.state.invitationId
    }
    if (getAttendanceListResponse.data.length > 0) {
      await patchAttendanceList(getAttendanceListResponse.data[0].id, 'anwesenheitslistenstatus', MeetingFormStepStatus.Draft);
    }
    if (invId) {
      await patchInvitation(invId, 'einladungsstatus', MeetingFormStepStatus.ReadyForProcessing, handleTokenAccessFailure)
    }
    if (agenda.data.length > 0) {
      await patchAgenda(agenda.data[0].id, 'tagesordnungstatus', MeetingFormStepStatus.NeedsReprocessing);
    }
    if (data.length > 0) {
      await patchProtokollSitzung('protokollstatus', MeetingFormStepStatus.NeedsReprocessing, data[0].id, handleTokenAccessFailure)
    }
    this.props.resetPhase()
  }

  onRoomChange = (e, v) => {
    let meeting = this.state.meeting;
    let meetingPrev = this.state.meetingPrev;
    let roomName = v.optionValue;
    const roomPrev = meeting.room;
    meeting.room = roomName;
    meetingPrev.room = roomPrev;
    if (roomName) {
      this.setState({ roomInput: v.optionValue, roomDisplayName: v.optionText })
    } else {
      meeting.room = this.state.roomInput
      roomName = this.state.roomInput
    }

    this.setState({ meeting: meeting, meetingPrev: meetingPrev }, () => {
      this.validateForm()
      if (this.props.meetingId != Guid.EMPTY) {
        this.saveDraft("room", roomName);
      }
      this.loadMeetingRooms()
    }
    );
    this.resetToPhaseOne()
  };

  onRoomInputChange = async (e) => {
    let meeting = this.state.meeting;
    let meetingPrev = this.state.meetingPrev;
    const roomName = e.target.value;
    const roomPrev = meeting.room;
    meeting.room = roomName;
    meetingPrev.room = roomPrev;
    this.setState({
      roomInput: e.target.value, roomDisplayName: e.target.value
    })
    if (this.state.typingTimer) {
      clearTimeout(this.state.typingTimer);
    }
    this.setState({
      typingTimer: setTimeout(() => {
        this.setState({ meeting: meeting, meetingPrev: meetingPrev }, () => {
          this.validateForm()
          if (this.props.meetingId != Guid.EMPTY) {
            this.saveDraft("room", roomName);
          }
          this.loadMeetingRooms()
        }
        );
        this.resetToPhaseOne()
      }, 1000),
    });
  }

  isFormValid = (field: string = '') => {
    return true;
  }

  isFormDisabled = () => {
    const ret = this.props.meetingStepStatus >= MeetingFormStepStatus.Processed
      || !userHasPermission(
        this.props.userPermissions,
        "SitzungCreate"
      )
      || !userHasPermission(
        this.props.userPermissions,
        "SitzungUpdate"
      )
      ||
      this.props.meetingHasStarted() || (this.props.meetingCompleted ? true : false);

    return ret;
  }

  saveDraft = async (
    propName: string | undefined = undefined,
    propValue: any = undefined,
    showLoader: boolean = false
  ) => {
    if (!this.state.isNameValid) {
      return false
    }

    // if (
    //   isEqual(this.state.meeting, this.state.meetingPrev) &&
    //   this.props.meetingId != Guid.EMPTY
    // ) {
    //   return true;
    // }

    if (showLoader) { this.props.setIsLoading(true); }

    if (this.state.typingTimer) {
      clearTimeout(this.state.typingTimer);
      this.setState({ typingTimer: undefined });
    }

    let response;
    if (
      propName != undefined &&
      propValue != undefined &&
      this.props.meetingId != Guid.EMPTY
    ) {
      response = await patchMeeting(this.props.meetingId, propName, propValue);
    } else {
      response =
        this.props.meetingId == Guid.EMPTY
          ? await postMeeting(this.state.meeting)
          : await putMeeting(this.props.meetingId, this.state.meeting);
    }

    if (showLoader) { this.props.setIsLoading(false); }

    if (response && response.status === StatusCodes.OK) {
      if (this.props.meetingId == Guid.EMPTY) {
        const meetingId = response.data.id;
        this.props.setMeetingId(meetingId);
        this.props.setMeetingTimes(response.data.start, response.data.end);
      }
      this.props.setMeetingTitle(response.data.name);
      return true;
    } else {
      window.alert("Erstellen der Sitzung Entwurf ist fehlgeschlagen.");
      this.setState(
        { meeting: cloneDeep(this.state.meetingPrev) },
      );
      return false;
    }
  };

  validateForm = () => {
    if (!this.state.isNameValid) {
      this.props.setIsFormValid(false)
      return
    }
    if ((this.state.meeting.sitzungsformat === 1 || this.state.meeting.sitzungsformat === 3) && !this.state.meeting.room) {
      this.props.setIsFormValid(false)
      return
    }
    this.props.setIsFormValid(true)
  }

  // reset = async (showLoader: boolean = false) => {
  //   const meeting = cloneDeep(this.state.meetingInitial);
  //   const meetingPrev = cloneDeep(this.state.meeting);

  //   await this.setState({ meeting: meeting, meetingPrev: meetingPrev });
  //   const result = await this.saveDraft(undefined, undefined, showLoader);
  //   return result;
  // };

  getHour = () => {
    if (this.state.meeting.start.getDate() === this.state.meeting.end.getDate()) {
      return this.state.meeting.start.getHours() as Hour
    }
    return 0 as Hour
  }

  getErrorMessage = (
    error?: TimePickerErrorType
  ): FieldProps["validationMessage"] => {
    switch (error) {
      case "invalid-input":
        return DateInvalidErrorMessage;
      case "out-of-bounds":
        return DateNotInRangeErrorMessage;
      case "required-input":
        return RequiredFieldErrorMessage;
      default:
        return "";
    }
  };

  //Start Time on pick
  onTimeChange: TimePickerProps["onTimeChange"] = (_ev, data) => {
    if (data.errorType) {
      this.setState({ errorType: data.errorType, errorTimeStart: true });
      return
    }
    this.setState({ errorTimeStart: false, selectedTime: data.selectedTime, timePickerValue: data.selectedTimeText ?? '' })
    this.setNewEndTime(data.selectedTime?.getHours(), data.selectedTime?.getMinutes())
    if (this.props.meetingStepStatus === MeetingFormStepStatus.Processed) {
      this.resetToPhaseOne()
    }
  };

  //End Time on pick
  onTimeChangeEnd: TimePickerProps["onTimeChange"] = async (_ev, data) => {
    if (data.errorType) {
      this.setState({ errorType: data.errorType, errorTimeEnd: true });
      return
    }
    const endTime = clone(this.state.meeting.end)
    if (data.selectedTime) {
      endTime.setHours(data.selectedTime.getHours())
      endTime.setMinutes(data.selectedTime.getMinutes())
    }
    this.setState({ errorTimeEnd: false, selectedTimeEnd: data.selectedTime, timePickerValueEnd: data.selectedTimeText ?? '' })
    if(this.props.meetingId && this.props.meetingId !== Guid.createEmpty().toString()){
      await patchMeeting(this.props.meetingId, 'end', endTime);
    }
    this.setState((prevState) => ({
      meeting: { ...prevState.meeting, end: endTime }
    }))
    if (this.props.meetingStepStatus === MeetingFormStepStatus.Processed) {
      this.resetToPhaseOne()
    }
  };

  //Start time on input change ( freeform )
  onTimePickerInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ timePickerValue: ev.target.value })
    const time = ev.target.value
    const timeSplit = time.split(':')
    const timeHours = timeSplit[0];
    const timeMinutes = timeSplit[1];
    if (this.state.meeting.start.getDate() === new Date().getDate() && parseInt(timeHours) < new Date().getHours()) {
      this.setState({ errorType: 'out-of-bounds', errorTimeStart: true })
      return
    }
    if (!timeHours || !timeMinutes) {
      this.setState({ errorType: 'invalid-input', errorTimeStart: true })
      return
    }
    if (parseInt(timeMinutes) > 59 || parseInt(timeHours) > 23 || timeMinutes.length !== 2) {
      this.setState({ errorType: 'invalid-input', errorTimeStart: true })
      return
    }
    this.setState({ errorTimeStart: false });
  };

  //End time on input change ( freeform )
  onTimePickerInputEnd = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ timePickerValueEnd: ev.target.value })
    const time = ev.target.value
    if (time) {
      const timeStart = this.state.timePickerValue
      const timeStartSplit = timeStart.split(':')
      const timeStartHours = timeStartSplit[0]
      const timeStartMinutes = timeStartSplit[1]

      const timeSplit = time.split(':')
      const timeHours = timeSplit[0];
      const timeMinutes = timeSplit[1];
      if (!timeHours || !timeMinutes) {
        this.setState({ errorTypeEnd: 'invalid-input', errorTimeEnd: true })
        return
      }
      if (parseInt(timeMinutes) > 59 || parseInt(timeHours) > 23 || timeMinutes.length !== 2) {
        this.setState({ errorTypeEnd: 'invalid-input', errorTimeEnd: true })
        return
      }
      if (this.state.meeting.start.getDate() >= this.state.meeting.end.getDate() && ((parseInt(timeStartHours) > parseInt(timeHours)) ||
        ((parseInt(timeStartHours) === parseInt(timeHours)) && parseInt(timeStartMinutes) > parseInt(timeMinutes)))) {
        this.setState({ errorTypeEnd: 'out-of-bounds', errorTimeEnd: true })
        return
      }
      this.setState({ errorTimeEnd: false });
    }
  };

  render() {
    return (
      <>
        <InfoBox label={"Hier starten Sie mit der Sitzungsvorbereitung. Mit dem “Weiter”-Button können Sie sich durch die einzelnen Schritte navigieren und Ihre Änderungen bleiben in der App gespeichert. Über die linke Menüleiste können Sie auch direkt zu den Punkten navigieren, an denen Sie noch etwas ändern möchten. Arbeitsschritte, die Sie bearbeitet haben, werden mit Häkchen versehen."}></InfoBox>
        <Text className="sitzung-form__section-title" as="h3">
          Sitzungdetails
        </Text>
        <div className="sitzung-details__wrapper">
          <Field
            validationMessage={
              this.state.isNameValid ? "" : this.state.errorMsgName
            }
            className="field-style-details"
            validationState={this.state.isNameValid ? "none" : "error"}
            label={
              <>
                {" "}
                Name der Sitzung&nbsp;
                <Tooltip
                  relationship="label"
                  content="Die Sitzung wird automatisch nach dem Datum in der Form „TT-MM-JJJJ“ benannt, so das die Reihenfolge der Sitzungen im Teams-Kanal chronologisch aufgebaut ist. Hier können Sie zusätzlich ein Schlagwort zur Sitzung eintragen, das später über die Suchfunktion ein Auffinden der Sitzung erleichtert."
                >
                  <span><InfoIcon /></span>
                </Tooltip>
              </>
            }
          >
            <Input
              style={{
                padding: "0 10px",
                width: "100%",
              }}
              contentBefore={formatDate(this.state.meeting.start)}
              value={this.state.meeting.name}
              onChange={this.onNameChange}
              disabled={this.isFormDisabled()}
            />
          </Field>
          <Field
            className="field-style-details"
            label={
              <>
                Art der Sitzung
                <RequiredSign />
                &nbsp;
                <Tooltip
                  relationship="label"
                  content="Wähle hier aus, ob es sich um eine ordentliche oder außerordentliche Sitzung handelt"
                >
                  <span><InfoIcon /></span>
                </Tooltip>
              </>
            }
          >
            <Dropdown
              value={
                sitzungTypes.find(
                  (t) => t.id == this.state.meeting.sitzungsart
                )?.name
              }
              onOptionSelect={this.onTypeChange}
              disabled={this.isFormDisabled()}
              className={this.isFormDisabled() ? "disabledInput" : "dropdown-style"}
            >
              {sitzungTypes.map((t) => (
                <Option key={t.id} value={t.name}>
                  {t.name}
                </Option>
              ))}
            </Dropdown>
          </Field>
          <Field
            className="sitzung-form__narrow-field field-style-details"
            validationMessage={this.state.errorTimeStart ? this.getErrorMessage(this.state.errorType) : ''}
            label={
              <>
                {" "}
                Beginn der Sitzung
                <RequiredSign />
                &nbsp;
                <Tooltip
                  relationship="label"
                  content="Tragen Sie hier den Sitzungsbeginn ein"
                >
                  <span><InfoIcon /></span>
                </Tooltip>
              </>
            }
          >
            {<div className="time-enabled">
              <DatePicker
                minDate={new Date()}
                formatDate={(date) => {
                  return formatDate(date)
                }}
                className="date-picker"
                onSelectDate={this.onStartChange}
                disabled={this.isFormDisabled()}
                value={this.state.meeting.start} />
              <TimePicker
                startHour={this.state.meeting.start.getDate() === new Date().getDate() ? new Date().getHours() as Hour : 0}
                dateAnchor={this.state.meeting.start ?? undefined}
                selectedTime={this.state.selectedTime}
                value={this.state.timePickerValue}
                freeform
                disabled={this.isFormDisabled() && this.state.meeting.start < new Date()}
                onTimeChange={this.onTimeChange}
                onInput={this.onTimePickerInput}
                hourCycle="h23"
                className="time-picker"
                expandIcon={<></>}
              />
            </div>
            }
          </Field>
          <Field
            className="sitzung-form__narrow-field field-style-details"
            validationMessage={this.state.errorTimeEnd ? this.getErrorMessage(this.state.errorTypeEnd) : ''}
            label={
              <>
                {" "}
                Ende der Sitzung
                <RequiredSign />
                &nbsp;
                <Tooltip
                  relationship="label"
                  content="Tragen Sie hier das geplante Sitzungsende ein"
                >
                  <span><InfoIcon /></span>
                </Tooltip>
              </>
            }
          >
            {<div className="time-enabled">
              <DatePicker
                formatDate={(date) => {
                  return formatDate(date)
                }}
                className="date-picker"
                minDate={this.state.meeting.start}
                onSelectDate={this.onEndChange}
                disabled={this.isFormDisabled()}
                value={this.state.meeting.end} />
              <TimePicker
                startHour={this.state.meeting.end.getDate() === this.state.meeting.start.getDate() ? this.state.meeting.start.getHours() as Hour : 0}
                dateAnchor={this.state.meeting.end ?? undefined}
                selectedTime={this.state.selectedTimeEnd}
                value={this.state.timePickerValueEnd}
                disabled={this.isFormDisabled() && this.state.meeting.start < new Date()}
                freeform
                onTimeChange={this.onTimeChangeEnd}
                onInput={this.onTimePickerInputEnd}
                hourCycle="h23"
                className="time-picker"
                expandIcon={<></>}
              ></TimePicker>
            </div>
            }
          </Field>
          <Field
            className="sitzung-form__narrow-field field-style-details"
            label={
              <>
                {" "}
                Format der Sitzung
                <RequiredSign />
                &nbsp;
                <Tooltip
                  relationship="label"
                  content="Wähle hier das gewünschte Sitzungsformat aus. Regelfall ist die Präsenzsitzung, Ausnahmen sind „online“ oder „hybrid“ (Präsenz und einige Mitglieder online zugeschaltet)."
                >
                  <span><InfoIcon /></span>
                </Tooltip>
              </>
            }
          >
            <Dropdown
              disabled={this.isFormDisabled() && this.state.meeting.start < new Date()}
              className={this.isFormDisabled() ? "disabledInput" : "dropdown-style"}
              onOptionSelect={this.onFormatChange}
              value={
                sitzungFormats.find(
                  (f) => f.id == this.state.meeting.sitzungsformat
                )?.name
              }
            >
              {sitzungFormats.map((format) => (
                <Option key={format.id} value={format.name}>
                  {format.name}
                </Option>
              ))}
            </Dropdown>
          </Field>
          {this.state.meeting.sitzungsformat !== 2 &&
            this.state.roomOptions.length > 0 && (
              <Field
                className="field-style-details"
                validationState={(this.state.meeting.sitzungsformat === 1 || this.state.meeting.sitzungsformat === 3) && !this.state.meeting.room && !this.props.meetingHasStarted() ? "error" : "none"}
                validationMessage={(this.state.meeting.sitzungsformat === 1 || this.state.meeting.sitzungsformat === 3) && !this.state.meeting.room && !this.props.meetingHasStarted() ? "Wählen Sie bitte einen Sitzungsraum aus." : ""}
                label={
                  <>
                    Sitzungsräume&nbsp;
                    <Tooltip
                      relationship="label"
                      content="Wähle hier den gewünschten Sitzungsraum aus."
                    >
                      <span><InfoIcon /></span>
                    </Tooltip>
                  </>
                }
              >
                <Combobox
                  defaultValue={''}
                  onOptionSelect={this.onRoomChange}
                  disabled={this.state.meeting.start < new Date()}
                  freeform
                  className={this.isFormDisabled() ? "disabledInput" : "dropdown-style"}
                  autoFocus
                  onChange={this.onRoomInputChange}
                  value={this.state.roomDisplayName}
                >
                  {this.state.roomOptions.map((room, i) => (
                    <Option key={room.value} value={room.value} text={room.displayName}>
                      {room.value ? <Persona
                        key={i}
                        presenceOnly
                        avatar={{ color: "colorful", "aria-hidden": true }}
                        name={room.displayName}
                        presence={room.availability === 2 ? {
                          status: "do-not-disturb",
                        } : room.availability === 1 ? { status: "available" } : { status: "unknown" }}
                        secondaryText={room.availability === 0 ? "Unbekannt" :
                          room.availability === 1 ? "Verfügbar" : "Nicht verfügbar"
                        }
                      /> : room.displayName}
                    </Option>
                  ))}
                </Combobox>
              </Field>
            )
          }
        </div>
      </>
    );
  }
}

export default MeetingDetails;
